import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import {
	LineChart,
	Line,
	XAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

export default function Dashboard() {
	const metamask = useSelector((state) => state.Metamask.data);
	const data = [
		{
			name: "Page A",
			uv: 4000,
			pv: 2400,
			amt: 2400,
		},
		{
			name: "Page B",
			uv: 3000,
			pv: 1398,
			amt: 2210,
		},
		{
			name: "Page C",
			uv: 2000,
			pv: 9800,
			amt: 2290,
		},
		{
			name: "Page D",
			uv: 2780,
			pv: 3908,
			amt: 2000,
		},
		{
			name: "Page E",
			uv: 1890,
			pv: 4800,
			amt: 2181,
		},
		{
			name: "Page F",
			uv: 2390,
			pv: 3800,
			amt: 2500,
		},
		{
			name: "Page G",
			uv: 3490,
			pv: 4300,
			amt: 2100,
		},
	];

	const FirstComponent = [
		{
			name: "BTC",
			icon: "/assets/img/crypto_coin/btc.png",
			price: "$00,000.00",
			fullname: "BitCoin",
		},
		{
			name: "ETH",
			icon: "/assets/img/crypto_coin/eth.png",
			price: "$00,000.00",
			fullname: "Ethereum",
		},
		{
			name: "BNB",
			icon: "/assets/img/crypto_coin/bnb.png",
			price: "$00,000.00",
			fullname: "BnbCoin",
		},
		{
			name: "LTC",
			icon: "/assets/img/crypto_coin/ltc.png",
			price: "$00,000.00",
			fullname: "LiteCoin",
		},
	];

	return (
		<>
			<Sidebar />
			<div className="container-fluid px-0 pb-3 d-flex flex-column justify-content-center">
				<Header />

				{/* Live Chart & Recent Transaction Section Start */}

				<div className="row my-lg-3 mt-lg-5 mb-lg-3 mt-5 mb-3 pt-5 mx-3">
					<div className="col-lg-7 col ms-auto px-lg-2 px-0">
						<div className="row mx-0 h-100 justify-content-center rounded-4 p-4 bg-secondary bg-opacity-10 border border-black border-opacity-50 shadow">
							<div className="d-lg-flex d-md-flex d-block align-items-start px-0 justify-content-between mb-2">
								<div className="dropdown px-0">
									<Link
										to=" "
										className="d-flex align-items-center justify-content-between text-white text-decoration-none dropdown-toggle mb-2"
										data-bs-toggle="dropdown"
										aria-expanded="false">
										<div className="d-flex align-items-center">
											<img
												src="/assets/img/crypto_coin/btc.png"
												className="img-fluid"
												alt=" "
												style={{ width: "2rem" }}
											/>
											<p className="text-white fs-5 ms-2 mb-0">Bitcoin (BTC)</p>
										</div>
									</Link>
									<ul className="dropdown-menu dropdown-menu-dark text-small shadow">
										<li>
											<Link
												className="dropdown-item d-flex align-items-center"
												to=" ">
												<img
													src="/assets/img/crypto_coin/btc.png"
													className="img-fluid me-2"
													alt=""
													style={{ width: "1.5rem" }}
												/>
												Bitcoin
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item d-flex align-items-center"
												to=" ">
												<img
													src="/assets/img/crypto_coin/eth.png"
													className="img-fluid me-2"
													alt=""
													style={{ width: "1.5rem" }}
												/>
												Ether
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item d-flex align-items-center"
												to=" ">
												<img
													src="/assets/img/crypto_coin/bnb.png"
													className="img-fluid me-2"
													alt=""
													style={{ width: "1.5rem" }}
												/>
												BNB coin
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item d-flex align-items-center"
												to=" ">
												<img
													src="/assets/img/crypto_coin/ltc.png"
													className="img-fluid me-2"
													alt=""
													style={{ width: "1.5rem" }}
												/>
												Lite coin
											</Link>
										</li>
									</ul>
									<p className="mb-0 text-white-50 fs-4">$00,000.00</p>
									<p className="mb-0 text-danger fw-semibold">
										-$000.00 (0.00)%
									</p>
								</div>
								<div className="d-lg-flex d-md-block d-grid gap-3 mt-lg-0 mt-3">
									<Link
										to="/buy"
										className="btn px-4 py-2 fw-semibold header_btn me-lg-0 me-md-3 me-0 text-decoration-none">
										Buy Crypto
									</Link>
									<Link
										to="/sell"
										className="btn btn-outline-light px-4 py-2 fw-semibold text-decoration-none">
										Sell Crypto
									</Link>
								</div>
							</div>
							<ResponsiveContainer className="me-4" width="100%" height={210}>
								<LineChart
									className="me-4"
									data={data}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									{/* <YAxis /> */}
									<Tooltip />
									<Line
										type="monotone"
										dataKey="pv"
										stroke="#8884d8"
										strokeDasharray="5 5"
									/>
									<Line
										type="monotone"
										dataKey="uv"
										stroke="#82ca9d"
										strokeDasharray="3 4 5 2"
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div className="col-lg-3 col mt-lg-0 mt-3 px-lg-2 px-0">
						<div className="h-100 rounded-4 p-4 bg-secondary bg-opacity-10 border border-black border-opacity-50 shadow">
							<p className="text-white px-0 fs-5 mb-4">
								Recent <span className="parallax">Transactions</span>
							</p>
							<div className="row flex-lg-column mx-0 g-4">
								<div className="col-lg-12 col-md-6 col-12 d-flex justify-content-between align-items-center">
									<div className="d-flex">
										<img
											src="/assets/img/crypto_coin/bnb.png"
											className="img-fluid shadow me-3"
											alt=""
											style={{ width: "3rem" }}
										/>
										<Link to=" " class="text-decoration-none">
											<p class="text-white mb-0">BNB</p>
											<p class="mb-0 text-white-50">BinanceCoin</p>
										</Link>
									</div>
									<p className="mb-0 text-white">$00.00</p>
								</div>
								<div className="col-lg-12 col-md-6 col-12 d-flex justify-content-between align-items-center">
									<div className="d-flex">
										<img
											src="/assets/img/crypto_coin/eth.png"
											className="img-fluid shadow me-3"
											alt=""
											style={{ width: "3rem" }}
										/>
										<Link to=" " class="text-decoration-none">
											<p class="text-white mb-0">ETH</p>
											<p class="mb-0 text-white-50">Ethereum</p>
										</Link>
									</div>
									<p className="mb-0 text-white">$00.00</p>
								</div>
								<div className="col-lg-12 col-md-6 col-12 d-flex justify-content-between align-items-center">
									<div className="d-flex">
										<img
											src="/assets/img/crypto_coin/btc.png"
											className="img-fluid shadow me-3"
											alt=""
											style={{ width: "3rem" }}
										/>
										<Link to=" " class="text-decoration-none">
											<p class="text-white mb-0">BTC</p>
											<p class="mb-0 text-white-50">BitCoin</p>
										</Link>
									</div>
									<p className="mb-0 text-white">$00.00</p>
								</div>
								<div className="col-lg-12 col-md-6 col-12 d-flex justify-content-between align-items-center">
									<div className="d-flex">
										<img
											src="/assets/img/crypto_coin/ltc.png"
											className="img-fluid shadow me-3"
											alt=""
											style={{ width: "3rem" }}
										/>
										<Link to=" " class="text-decoration-none">
											<p class="text-white mb-0">LTC</p>
											<p class="mb-0 text-white-50">LiteCoin</p>
										</Link>
									</div>
									<p className="mb-0 text-white">$00.00</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Live Chart & Recent Transaction Section End */}

			</div>
		</>
	);
}
