import { FaUserAlt, FaCog, FaTachometerAlt, FaLock } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

export default function Sidebar(props) {
	const metamask = useSelector((state) => state.Metamask.data);
	const menuItem = [
		{
			path: "/account/dashboard",
			name: "Dashboard",
			icon: <FaTachometerAlt />,
		},
		{
			path: "/account/buyCryptoTransaction",
			name: "Transaction\n(Buy Crypto)",
			icon: <FaUserAlt />,
		},
		{
			path: "/account/sellCryptoTransaction",
			name: "Transaction\n(Sell Crypto)",
			icon: <FaUserAlt />,
		}
	];

	return (
		<>
			<div id="Sidebar" className="container-fluid px-0">
				<div className="row mx-0">
					<div className="col d-lg-block d-flex mt-5 px-0 justify-content-md-center position-fixed border border-black border-opacity-50 sidebar_bg">
						<div className="dropdown d-lg-block d-none border-bottom border-secondary border-opacity-50 mx-3 pt-4 pb-2">
							<ul className="dropdown-menu dropdown-menu-dark text-small shadow">
								<li>
									<Link
										className="dropdown-item d-flex justify-content-between"
										to=" ">
										USD
										<p className="mb-0">$0.00</p>
									</Link>
								</li>
								<li>
									<Link
										className="dropdown-item d-flex justify-content-between"
										to=" ">
										BTC
										<p className="mb-0">0</p>
									</Link>
								</li>
								<li className="px-3 d-grid">
									<Link className="btn header_btn mt-3 fw-semibold py-1" to=" ">
										Show in USD
									</Link>
								</li>
							</ul>
						</div>
						<div className="d-flex flex-column justify-content-center px-lg-3 px-0">
							<div className="d-flex flex-lg-column px-lg-0 py-lg-4 py-2 gap-md-5 gap-3 gap-lg-3">
								{menuItem.map((item, index) => {
									return (
										<>
											<NavLink
												to={item.path}
												key={index}
												className="nav-link p-2 d-flex gap-2 align-items-center rounded links"
												activeclassName="active">
												<div className="icon align-self-center my-auto parallax">
													{item.icon}
												</div>
												<p className="text-white mb-0">{item.name}</p>
											</NavLink>
										</>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			{props.children}
		</>
	);
}
