import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: {
		Address: "",
		Balance: "",
	},
};

export const Metamask = createSlice({
	name: "counter",
	initialState,
	reducers: {
		update_address: (state, action) => {
			state.data.Address = action.payload;
		},
		update_balance: (state, action) => {
			state.data.Balance = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { update_address, update_balance } = Metamask.actions;

export default Metamask.reducer;
