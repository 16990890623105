import AccountLayout from "../Layout/AccountLayout";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import Loader from "../Components/Loader";
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

export default function Home() {
  const { address, isConnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const { disconnect } = useDisconnect()
  
  return (
    <>
      <AccountLayout>
        <div className="pt-4 mt-5 px-0">
          {/* Hero Section Start */}

          <div className="row mx-0 p-lg-5 px-3 background_img">
            <div className="col-lg-6 my-5 px-0">
              <div className="d-flex flex-column gap-4">
                <h1 className="display-3 text-white lh-sm fw-bold lh-1">
                  Discover The Best NFTs{" "}
                  <span className="parallax">Digital Art.</span>
                </h1>
                <p className="text-light fs-6">
                  Quickly design and customize responsive mobile-first sites
                  with Bootstrap, the world’s most popular and powerful
                  JavaScript plugins.
                </p>
              </div>
              <div className="d-grid d-lg-flex gap-2 my-4 flex-wrap">
                <button
                  className="btn header_btn px-5 py-2 shadow fw-semibold"
                  type="button"
                >
                  Get Started
                </button>
                <button
                  className="btn text-white justify-content-center icon-link icon-link-hover px-5 py-2 "
                  type="button"
                >
                  Learn More
                  <i
                    className="fa-solid fa-arrow-right ms-1 bi"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div className="row d-flex gap-5 mt-5 mx-lg-0 mx-2 pt-3">
                <div className="col-lg-3 flex flex-column pe-lg-5 pe-0 text-center border_right">
                  <p className="fw-semibold text-white members">
                    <span className="parallax">10.0</span>K
                  </p>
                  <p className="text-white mb-0">Art Work</p>
                </div>
                <div className="col-lg-3 flex flex-column pe-lg-5 pe-0 text-center border_right">
                  <p className="fw-semibold text-white members">
                    <span className="parallax">9.0</span>K
                  </p>
                  <p className="text-white mb-0">Artist</p>
                </div>
                <div className="col-lg-3 flex flex-column pe-lg-5 pe-0 text-center">
                  <p className="fw-semibold text-white members">
                    <span className="parallax">8.3</span>K
                  </p>
                  <p className="text-white mb-0">Collection</p>
                </div>
              </div>
            </div>
          </div>
          {/* Hero Section End */}
        </div>
      </AccountLayout>
    </>
  );
}
