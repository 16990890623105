import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function AccountLayout(props) {
	return (
		<>
			<div className="container-fluid px-0 d-flex justify-content-center">
				<div className="col-12">
					<Header />
					{props.children}
					<Footer />
				</div>
			</div>
		</>
	);
}
