import { FaFacebookF, FaGoogle, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer(props) {
	return (
		<>
			<div className="container-fluid px-0 border-top border-secondary border-opacity-50 text-white bg-black">
				<footer className="row mx-lg-5 mx-3 mt-4 text-center">
					<div className="col-lg px-0 mb-5 mb-lg-3">
						<p className="mt-2 mb-0">
							Copyright © 2023 BFC TOKEN. All rights reserved.
						</p>
					</div>
				</footer>
			</div>
			{props.children}
		</>
	);
}
