import Home from "./Pages/Home";
import Buycrypto from "./Pages/Buycrypto";
import Sellcrypto from "./Pages/Sellcrypto";
import Dashboard from "./Pages/Dashboard";
import BuyCryptoTransaction from "./Pages/BuyCryptoTransaction";
import SellCryptoTransaction from "./Pages/SellCryptoTransaction";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WagmiConfig, createConfig, mainnet } from 'wagmi';
import { createPublicClient, http } from 'viem';
const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http()
  }),
});

function App() {
  return (
    <WagmiConfig config={config}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buy" element={<Buycrypto />} />
          <Route path="/sell" element={<Sellcrypto />} />
          <Route path="/account/dashboard" element={<Dashboard />} />
          <Route path="/account/buyCryptoTransaction" element={<BuyCryptoTransaction />} />
          <Route path="/account/sellCryptoTransaction" element={<SellCryptoTransaction />} />
        </Routes>
      </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
