import { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export default function SellCryptoTransaction() {
	const { address, isConnected } = useAccount();
	const [cryptoData, setCryptoData] = useState(false);
	useEffect(() => {
		if (isConnected) {
			axios.post('https://api.bfcswap.finance/api/crypto/sellCryptoTransaction', { walletAddress: address }).then((response) => {
				setCryptoData(response.data);
			});
		}
	}, [address, isConnected]);
	return (
		<>
			<Sidebar />
			<div className="container-fluid px-0 pb-3 d-flex flex-column justify-content-center">
				<Header />

				{/* Marketing Section Start */}

				<div className="row my-lg-3 mt-lg-5 mb-lg-3 mt-5 mb-3 pt-5 mx-3">
					<div className="col-lg-10 ms-auto px-lg-2 px-0">
						<div className="rounded-4 bg-secondary bg-opacity-10 border border-black border-opacity-50 shadow">
							<h2 className="text-white p-4 mb-0">
								Transactions <span className="parallax">Sell Crypto</span>
							</h2>
							<div className="table-responsive table-responsive-sm">
								<table className="table table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="border-0 text-start ps-lg-5 px-4"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												userFromAddress
											</th>
											<th
												scope="col"
												className="border-0 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												userToAddress
											</th>
											<th
												scope="col"
												className="border-0 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												userCryptoCoin
											</th>
											<th
												scope="col"
												className="border-0 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												userTotalAmount
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												userTxHash
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												adminFromAddress
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												adminToAddress
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												adminCryptoCoin
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												adminTotalAmount
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												adminTxHash
											</th>
											<th
												scope="col"
												className="border-0 pe-lg-0 pe-4 text-center"
												style={{
													width: "20vh",
													backgroundColor: "#161616",
													color: "gray",
												}}>
												createdAt
											</th>
										</tr>
									</thead>
									<tbody>
										{
											cryptoData && cryptoData.length > 0 ? cryptoData.map((crypto, index) => (
												<tr className="text-center">
													<td
														className="lh-lg border-0 py-3 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.userFromAddress}
													</td>
													<td
														className="lh-lg border-0 py-3 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.userToAddress}
													</td>
													<td
														className="lh-lg border-0 py-3 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.userCryptoCoin}
													</td>
													<td
														className="lh-lg border-0 py-3 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.userTotalAmount}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.userTxHash}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.adminFromAddress}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.adminToAddress}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.adminCryptoCoin}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.adminTotalAmount}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.adminTxHash}
													</td>
													<td
														className="lh-lg border-0 py-3 pe-lg-0 pe-4 text-white-50"
														style={{
															width: "20vh",
															backgroundColor: "#1f2021",
															color: "gray",
														}}>
														{crypto.createdAt}
													</td>
												</tr>
											)) : ""
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				{/* Marketing Section End */}
			</div>
		</>
	);
}
