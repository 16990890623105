import { useState, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSwitchNetwork, useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

export default function Header(props) {
	const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({ chainId: 56 });
	const { address, isConnected } = useAccount()
	const { connect } = useConnect({
		connector: new InjectedConnector(),
	})
	const { disconnect } = useDisconnect();
	const [scrolled, setScrolled] = useState(false);
	useEffect(() => {
		if (switchNetwork) {
			switchNetwork();
		}
		const handleScroll = () => {
			const isScrolled = window.scrollY > 0;
			if (isScrolled) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [switchNetwork]);
	return (
		<>
			<nav
				className={`${scrolled ? "scrolled" : ""} container-fluid navbar z-3 py-lg-0 px-lg-3 p-3 fixed-top navbar-expand-lg`}
				aria-label="Offcanvas navbar large">
				<div className="container-fluid px-0">
					<Link
						to="/"
						className="d-flex align-items-center mb-0 text-white text-decoration-none">
						<p className="fs-2 font-bold text-white mb-0 pe-5">
							BFC TOKEN
						</p>
					</Link>
					<button
						className="navbar-toggler bg-white"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasNavbar2"
						aria-controls="offcanvasNavbar2"
						aria-label="Toggle navigation">
						<span className="navbar-toggler-icon" />
					</button>
					<div
						className="offcanvas offcanvas-end text-bg-dark"
						tabIndex={-1}
						id="offcanvasNavbar2"
						aria-labelledby="offcanvasNavbar2Label">
						<div className="offcanvas-header bg-black">
							<h5
								className="offcanvas-title font-bold fs-2"
								id="offcanvasNavbar2Label">
								BFC TOKEN
							</h5>
							<button
								type="button"
								className="btn-close btn-close-white"
								data-bs-dismiss="offcanvas"
								aria-label="Close"
							/>
						</div>
						<div className="offcanvas-body mt-lg-0 mt-4">
							<ul
								id="header_link"
								className="navbar-nav justify-content-center gap-4 align-items-lg-center flex-grow-1">
								<NavLink
									to="/buy"
									className="nav-link p-0 navbar_li my-lg-4 mx-lg-2"
									activeclassname="active">
									Buy Crypto
								</NavLink>
								<NavLink
									to="/sell"
									className="nav-link p-0 navbar_li my-lg-4 mx-lg-2"
									activeclassname="active">
									Sell Crypto
								</NavLink>
								{isConnected ? (
									<>
										<NavLink
											to="/account/dashboard"
											className="nav-link p-0 navbar_li my-lg-4 mx-lg-2"
											activeclassname="active">
											Dashboard
										</NavLink>
										<NavLink
											onClick={() => disconnect()}
											className="nav-link p-0 navbar_li my-lg-4 mx-lg-2"
											activeclassname="active">
											Disconnect
										</NavLink>
									</>
								) : (
									<></>
								)}
							</ul>
							<ul id="header_link" className="navbar-nav">
								<li className="nav-item mt-5 my-lg-3 mx-lg-2">
									<Link className="nav-link text-white p-0" to=" ">
										{isConnected ? (
											<>
												<button className="btn px-3 py-2 fw-semibold shadow header_btn">
													Address: {address.slice(0, 5) + "...." + address.slice(-7, address.length)}
												</button>
											</>
										) : (
											<button
												type="button"
												className="btn px-4 py-2 fw-semibold shadow header_btn"
												onClick={() => connect()}>
												Connect Wallet
											</button>
										)}
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
			{props.children}
		</>
	);
}
